import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { PageTitle } from '../components/pages';
import '../components/layout.css';

const Page = styled.div`
    background-color: rgb(245, 243, 240);
    padding-bottom: 120px;
    height: 100vh;
    @media (min-width: 768px) {
        padding-top: 100px;
    }
`;

const PageTitleReg = styled(PageTitle)`
  color: #000;
`;

const RegPoint = styled.div`
  font-family: "Montserrat Medium", sans-serif;
  color: #000;
  font-size: 16px;
  display: flex;
  max-width: 1400px;
  padding: 0 35px 0;
  margin: 0 auto 15px;
`;

const Index = styled.div`
  margin-right: 8px;
  min-width: 24px;
  display: flex;
  justify-content: center;
`;

const Text = styled.div`
  
`;

const About = () => (
  <Page id="rejestracja_udana" className="page">
    <PageTitleReg>
      Baza Centrum 2019 - Regulamin
    </PageTitleReg>
    <RegPoint>
      <Index>1.</Index>
      <Text>
        Jeżeli masz mniej niż 18 lat, przynieś pisemną zgodę rodzica razem z wyznaczeniem pełnoletniego opiekuna.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>2.</Index>
      <Text>
        Noś opaskę do końca konferencji.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>3.</Index>
      <Text>
        Nie zabieraj ze sobą i nie używaj alkoholu, papierosów ani narkotyków.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>4.</Index>
      <Text>
        Wszystkie punkty programu są obowiązkowe.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>5.</Index>
      <Text>
        Nie jedz i nie pij w miejscu noclegu oraz w hali głównej.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>6.</Index>
      <Text>
        Nie wchodź do stref przeznaczonych wyłącznie dla staffu, łącznie ze sceną.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>7.</Index>
      <Text>
        Teren konferencji i sprzęt techniczny służy nam wszystkim - szanuj go i dbaj o porządek wokół siebie.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>8.</Index>
      <Text>
        Wyśpij się i daj się wyspać innym. Jeśli nie lubisz spać pod chmurką, wróć na miejsca noclegu na czas.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>9.</Index>
      <Text>
        Nie ponosimy odpowiedzialności za rzeczy zgubione lub uszkodzone.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>10.</Index>
      <Text>
        Jeśli widzisz coś niepokojącego lub zdarzył się wypadek, zgłoś się do punktu info.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>11.</Index>
      <Text>
        Ubieraj się stosownie do okazji – na Bazie Centrum noś zakryty brzuch, pośladki, a także nie prezentuj obraźliwych i wulgarnych treści.
      </Text>
    </RegPoint>
    <RegPoint>
      <Index>12.</Index>
      <Text>
        Niestosowanie się do regulaminu będzie skutkowało konsekwencjami, a w uzasadnionych przypadkach odesłaniem uczestnika do domu.
      </Text>
    </RegPoint>
  </Page>
);

export default About;
